<template>
  <h1 style="text-align: center;">第三方SDK目录</h1>
  <h3>1. 内嵌SDK</h3>
  <p> 登录类SDK</p>
  <p> SDK名称：微信开放平台</p>
  <p> 公司名称：深圳市腾讯计算机系统有限公司</p>
  <p> 使用目的：提供微信账号登录的服务</p>
  <p> 使用场景：使用微信账号登录</p>
  <p> 收集的个人信息类型/字段：设备标识信息 （OAID, AndroidId, IDFA)</p>
  <p> 数据传输方式：客户端接口传输</p>
  <p> 隐私政策/官网:</p>
  <p><a href="https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a>
  </p>
  <h3>2. 社交类服务SDK</h3>
  <p> SDK名称：微信开放平台</p>
  <p> 公司名称：深圳市腾讯计算机系统有限公司</p>
  <p> 使用目的：提供内容分享至微信的服务</p>
  <p> 使用场景：分享内容至微信</p>
  <p> 收集的个人信息类型/字段：设备标识信息 （OAID, AndroidId, IDFA)</p>
  <p> 数据传输方式：客户端接口传输</p>
  <p> 隐私政策/官网:</p>
  <p><a href=" https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8">
    https://support.weixin.qq.com/cgi-bin/mmsupportacctnodeweb-bin/pages/RYiYJkLOrQwu0nb8</a></p>
  <h3>3. 统计分析类服务SDK</h3>
  <p> SDK名称：百度移动统计</p>
  <p> 公司名称：百度在线网络技术（北京）有限公司</p>
  <p> 使用目的：提供统计数据及效果分析的服务</p>
  <p> 使用场景：进行数据统计和效果分析</p>
  <p> 收集的个人信息类型/字段：设备标识信息 （OAID, AndroidId, IDFA), 地理位置, 网络状态, 手机版本号.</p>
  <p> 数据传输方式：客户端接口传输</p>
  <p> 隐私政策/官网:</p>
  <p><a href="https://tongji.baidu.com/web/help/article?id=330&amp;castk=LTE%3D">https://tongji.baidu.com/web/help/article?id=330&amp;castk=LTE%3D</a>
  </p>
  <p> SDK名称：腾讯Bugly SDK</p>
  <p> 公司名称：深圳市腾讯计算机系统有限公司</p>
  <p> 使用目的：提供数据及错误分析的服务</p>
  <p> 使用场景：进行数据及错误分析</p>
  <p> 收集的个人信息类型/字段：设备标识信息 （OAID, AndroidId, IDFA), 地理位置, 网络状态, 手机版本号.</p>
  <p> 数据传输方式：客户端接口传输</p>
  <p> 隐私政策/官网:</p>
  <p><a href="https://privacy.qq.com/document/preview/179ae701076241418eaf276f7595ee54">https://privacy.qq.com/document/preview/179ae701076241418eaf276f7595ee54</a>
  </p>
  <p> 使用SDK名称：友盟SDK</p>
  <p> 公司名称：北京锐讯灵通科技有限公司</p>
  <p> 服务类型：提供数据及错误统计分析</p>
  <p> 使用场景：进行数据及错误分析</p>
  <p> 收集个人信息类型：设备信息（IMEI/MAC/Android ID/OAID/IDFA/OpenUDID/GUID/IP地址/ICCID/SIM 卡 IMSI 信息等）</p>
  <p> 数据传输方式：客户端接口传输</p>
  <p> 隐私政策/官网:</p>
  <p><a href="https://www.umeng.com/page/policy">https://www.umeng.com/page/policy</a>
  </p>
</template>

<script>
export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Sdk"
}
</script>

<style scoped>
p {
  text-indent: 2em; /* 设置文本缩进为2em，大约等于两格 */
}
</style>
