<script setup>

</script>

<template>
  <body class="page-body black">
  <div class="row">
    <div class="column">
      <div class="card" >
        <h3>活出愛</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>

    <div class="column">
      <div class="card" >
        <h3>牽我手</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>

    <div class="column">
      <div class="card" >
        <h3>愛中相遇</h3>
        <p>Hugging Face NLP理解模型实现对歌词情绪的分析</p>
      </div>
    </div>

    <div class="column">
      <div class="card" >
        <h3>我在這裡</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>我願為祢去</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>心肝寶貝</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>奔跑不放棄</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>同行</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>能不能</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>將天敞開</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>全然美麗</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>謝謝祢</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>彩虹下的約定</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>良人和我</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>
    <div class="column">
      <div class="card" >
        <h3>同路人</h3>
        <p>CRNN tensor flow 深度学习通过对音乐的mfcc进行分类</p>
      </div>
    </div>

  </div>

  </body>
</template>

<style scoped>
body {
  margin: 0;
  font-family: Arial, Helvetica, sans-serif;
}

.topnav {
  overflow: hidden;
  position: fixed;
  top: 0;
  left:0;
  width: 100%;
  background-color: #f1f1f1;
}

.topnav a {
  float: left;
  color: #f2f2f2;
  text-align: center;
  padding: 14px 16px;
  text-decoration: none;
  font-size: 17px;
}

.topnav a.active {
  background-color: #FF0000;
  color: white;
}
/* Float four columns side by side */
.column {
  float: left;
  width: 80%;
  padding: 0 10px;
  margin-bottom: 20px;
}

/* Remove extra left and right margins, due to padding */
.row {
  margin: 60px  20px 15px;
}

/* Clear floats after the columns */
.row:after {
  content: "";
  display: table;
  clear: both;
}

/* Responsive columns */
@media screen and (max-width: 600px) {
  .column {
    width: 100%;
    display: block;
    margin-bottom: 20px;
  }
}

/* Style the counter cards */
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  padding: 16px;
  text-align: left;
  background-color: #f1f1f1;
}

</style>