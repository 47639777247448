<script>

</script>

<template>
  <body>
  <main>
    <img src="../assets/image_one.webp" class="img"/>
    <br>
    <br>
    <br>
    <p class="text">
      Engine Prime v1.1的更新是Denon DJ专有音乐分析和图书管理员软件的最新版本，具有改进的iTunes文件夹管理、改善的库搜索能力和性能提升。现在可以在音轨仍在分析时添加循环和提示点，搜索也变得更加灵活和强大。
    </p>



  </main>

  <footer>
           <span >
            <a href="https://beian.miit.gov.cn" target="_blank" style="color: lightgray">备案号：粤ICP备2023133124号-1</a>
          </span>
    <span>
              Copyright© 2023 广州市禾唱网络技术有限责任公司 All Rights Reserved
          </span>
  </footer>
  </body>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.text {
  color: white;
  width: 100%;
  text-align: center;
  padding-left: 20%;
  padding-right: 20%;
}
.img {
  width: 40%;
  height: auto;
}


body {
  background-color: black;
  position: fixed;
  top: 0;
  bottom:0;
  left:0;
  width: 100%;
  height: 100%;
}

main {
  height: 100vh;
  padding-top: 10vh;
  overflow-y: auto;
}

footer {
  left: 0;
  right: 0;
  height: 58px;
  background-color: rgba(204, 204, 204, 0.1);
  color: #ccc;
  display: block;
  text-align: center;
  line-height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer span:first-of-type {
  margin-right: 30px;
}

@media (max-width: 992px) {
  main {
    padding-top: 8vh;
  }

  .text {
    padding-right: 5%;
    padding-left: 5%;
  }
  .img {
    width: 85%;
    height: auto;
  }

  .app-info-wrapper {
    display: none;
  }

  .app-info-wrapper-mobile {
    display: block;
    width: 90%;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .app-logo {
    width: 77px;
    display: block;
    margin-bottom: 14px;
  }

  .app-name {
    font-size: 23px;
    font-weight: bolder;
    text-align: center;
  }

  .download-btn {
    padding: 8px 12px;
    border-radius: 8px;
  }

  .android-logo {
    height: 30px;
    width: 28px;
  }

  footer {
    display: block;
    height: 58px;
    padding-top: 8px;
  }

  footer span {
    font-size: 12px;
    display: block;
    text-align: center;
    line-height: 16px;
  }

  footer span:first-of-type {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  body {
    background-color: black;
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>