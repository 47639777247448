<script setup>
</script>

<template>
  <body>
  <main>
    <div class="app-title">
      <img class="app-logo" src="../assets/logo.png" alt="灵听"/>
    </div>
    <div class="app-title" style="margin-bottom: 63px">
      <div class="app-name">灵听</div>
    </div>

    <div class="app-info-wrapper">
      <p class="app-info">
        灵听是一款分享不同类型的语音技术及声音识别网络技术工具。
      </p>
      <p class="app-info">
        亦可使用该应用为客户提供情感类型的歌曲, 歌词分析技术。
      </p>
    </div>
    <div class="app-info-wrapper-mobile">
      灵听是一款分享不同类型的语音技术及声音识别网络技术工具。亦可使用该应用为客户提供情感类型的歌曲, 歌词分析技术。
    </div>
    <div class="download-btn-wrapper" id="liveToastBtn">
      <a class="download-btn" href="https://listen-to-heart-1320693856.cos.ap-nanjing.myqcloud.com/apk/home/lingtingmusic.apk" >
        <img class="android-logo" src="../assets/Android.png"/>
        <span style="color: black; ">安卓下载</span>
      </a>
    </div>

    <div class="download-btn-wrapper" id="liveToastBtn">
      <a class="download-btn-apple" href="https://apps.apple.com/us/app/%E7%81%B5%E5%90%AC%E9%9F%B3%E4%B9%90/id6502691630" >
        <img class="android-logo" src="../assets/ic_apple_login.svg"/>
        <span style="color: white">苹果下载</span>
      </a>
    </div>
  </main>
  <footer>
      <span>
            <a href="https://beian.miit.gov.cn" target="_blank" style="color: lightgray">备案号：粤ICP备2023133124号-1</a>
      </span>
    <span>
            <a href="https://beian.mps.gov.cn/#/query/webSearch" target="_blank" style="color: lightgray">公安备案号：粤公网安备44010402003148号</a>
    </span>
    <span>
      &nbsp;
    </span>
    <span>
            Copyright© 2023 广州市禾唱网络技术有限责任公司 All Rights Reserved
    </span>
    <span>
      &nbsp;&nbsp;
    </span>
    <span>
      <router-link to="/feedback" style="color: lightgray">意见反馈</router-link>
    </span>


  </footer>
  </body>
</template>

<style scoped>
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  background-image: url('https://listen-to-heart-1320693856.cos.ap-nanjing.myqcloud.com/Web/PC-BG.webp');
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

main {
  height: 100vh;
  padding-top: 18vh;
  overflow-y: auto;
}

.download-btn {
  background-color: #fff;
  padding: 15px 30px;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}

.download-btn-apple {
  background-color: #000;
  padding: 15px 30px;
  border-radius: 14px;
  display: inline-flex;
  align-items: center;
  cursor: pointer;
}

.android-logo {
  width: 30px;
}

.app-title, .app-info, .app-info-wrapper-mobile {
  color: #fff;
}

.app-title {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.app-logo {
  width: 100px;
  display: block;
  margin-bottom: 24px;
}

.app-name {
  font-size: 32px;
  font-weight: bolder;
  text-align: center;
}

.app-info-wrapper {
  margin-bottom: 50px;
}

.app-info {
  text-align: center;
  line-height: 28px;
  margin-bottom: 0;
}

.app-info-wrapper-mobile {
  display: none;
  line-height: 28px;
  margin: auto;
  text-align: justify;
}

.download-btn-wrapper {
  display: flex;
  justify-content: center;
  margin-bottom: 25px;
  text-decoration: none;
}

.android-logo {
  height: 40px;
  margin-right: 10px;
}

footer {
  left: 0;
  right: 0;
  height: 58px;
  background-color: rgba(204, 204, 204, 0.1);
  color: #ccc;
  display: block;
  text-align: center;
  line-height: 56px;
  position: fixed;
  bottom: 0;
  width: 100%;
}

footer span:first-of-type {
  margin-right: 30px;
}

@media (max-width: 992px) {
  main {
    padding-top: 15vh;
  }

  .app-info-wrapper {
    display: none;
  }

  .app-info-wrapper-mobile {
    display: block;
    width: 90%;
    margin-bottom: 50px;
  }
}

@media (max-width: 768px) {
  .app-logo {
    width: 77px;
    display: block;
    margin-bottom: 14px;
  }

  .app-name {
    font-size: 23px;
    font-weight: bolder;
    text-align: center;
  }


  .android-logo {
    height: 30px;
    width: 28px;
  }

  footer {
    display: block;
    height: 58px;
    padding-top: 8px;
  }

  footer span {
    font-size: 12px;
    display: block;
    text-align: center;
    line-height: 16px;
  }

  footer span:first-of-type {
    margin-right: 0;
  }
}

@media (max-width: 576px) {
  body {
    background-image: url('https://listen-to-heart-1320693856.cos.ap-nanjing.myqcloud.com/Web/MB-BG.webp');
    background-size: cover;
    background-position: center center;
    background-repeat: no-repeat;
  }
}
</style>
